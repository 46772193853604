// import logo from './logo.svg';
import './App.css';
import { 
  HelmetProvider,
  Helmet,
} from 'react-helmet-async';

import {JaaSMeeting} from '@jitsi/react-sdk';

const YOCC_APP_ID = 'vpaas-magic-cookie-ba5bc89965634de79ae259fac149e568';
// const YOCC_JWT = 'eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtYmE1YmM4OTk2NTYzNGRlNzlhZTI1OWZhYzE0OWU1NjgvZmY2OTljLVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE3MTI4NTIyNDAsImV4cCI6MTcxMjg1OTQ0MCwibmJmIjoxNzEyODUyMjM1LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtYmE1YmM4OTk2NTYzNGRlNzlhZTI1OWZhYzE0OWU1NjgiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJoaWRkZW4tZnJvbS1yZWNvcmRlciI6ZmFsc2UsIm1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6InJhbW15MTIzMSIsImlkIjoiZ29vZ2xlLW9hdXRoMnwxMTA5ODA1ODc3NzU2MDM2NDQ0MzIiLCJhdmF0YXIiOiIiLCJlbWFpbCI6InJhbW15MTIzMUBnbWFpbC5jb20ifX0sInJvb20iOiIqIn0.mD5uLSdMEVfHZqAP4iatm1pGqVhVbs03WKiPIN8egX4WAqRtRVgSBL-93y08W0NrysFpuELtqcKtpoMSATzMnGGEK0ovAcwo2pjFE7HxiLXTKTDgZeoDzsc1gf6VMuHW_3kAeKQlyJqWcHqK6ZWdnqlekCj0CFIcjqYaIhCOPemcqIn3092Lmkbt60Kei6QLxCOl48YrpDoI1_mCKlyruzNqZ-7Py4tMbQA62wmTlvrJea2EPR5qHKDt7EAOUJsV7lbMINrhL0HUlFq0AClixPzyyU81TVO15_6hx03jJ8BTix4XEbLmXtu8gvH3ChuI0DEBMJece9zFFG1v-DD6UQ';
// const YOCC_ROOM_NAME = "vpaas-magic-cookie-ba5bc89965634de79ae259fac149e568/YOCC";
const YOCC_ROOM_NAME = "YOCC Test Room";

function App() {

  const helmetContext = {};

  const renderSpinner = () => (
    <div style = {{
        fontFamily: 'sans-serif',
        textAlign: 'center'
    }}>
        Loading...
    </div>
  );
  

  return (
    <HelmetProvider context={helmetContext}>

      <Helmet>
        <title>Welcome to YOCC test app!</title>
      </Helmet>

      <JaaSMeeting
        appId = { YOCC_APP_ID }
        roomName = { YOCC_ROOM_NAME }
        spinner={renderSpinner}
    />

    </HelmetProvider>
  )

}

export default App;
